import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store/definitions';
import {
  selectCurrentFacility,
  selectCurrentZones,
} from '../../store/zone/selector';
import styled, { useTheme } from 'styled-components';
import { useDemandContext } from './DemandContext';

import {
  ResponsiveContainer,
  ComposedChart,
  // Bar,
  // Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ReferenceLine,
  // Cell,
  // TooltipProps,
} from 'recharts';
import { dataZip, numToSpacedString, isSpecialDay } from './GraphData';
import Spinner from 'components/Spinner';
import GraphLoader from 'components/Loader/graphLoader';
import {
  GraphData,
  GraphDataBundle,
  GTCompareKeys,
  GTransformer,
} from './defs';
import {
  selectCalcStepLengthMinutes,
  selectUiStateCDataUpdateTS,
  selectUiStateDataUpdateTS,
  selectUiStateDuration,
  selectUIStateEnableCompare,
  selectUiStateEndDate,
  selectUiStatePriceTypeViewID,
  selectUIStateRevView,
  selectUiStateStartDate,
  selectUIValidPriceTypes,
} from 'store/ui/selector';
import AccRevenueSticker, {
  useAccRevContext,
} from 'containers/AccRevenueSticker';
import moment from 'moment';
import { addCharToNum } from 'util/range';
import { selectCurrentViewCompareDataSet } from 'store/compareCache/selector';
import { selectAllEventsFromCurrentFacility } from '../../store/event/selector';
import { ZoneFlag } from 'store/zone/reducer';
import { FacilityFlag } from 'store/uiSettings/reducer';
import { hasFlag } from 'util/facility';
import {
  selectCalcCacheData,
  selectCompCalcCacheData,
} from 'store/calcCache/selector';

export const yAxisWidth = 80;

const Rotating = styled.span`
  width: 40px;
  height: 40px;
  text-align: center;
  animation-name: rotating;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const MarginWrapper = styled.div`
  margin: 0px 0;

  margin-left: ${-yAxisWidth}px;
  margin-right: ${-yAxisWidth}px;

  position: relative;
`;

const AspectWrapOuter = styled.div`
  position: relative;
  width: 100%;
  padding-top: 40%;
`;
const AspectWrapInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const LoaderHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  //aspect-ratio: 2.5;
  height: 100%;

  font-size: 30px;
  font-weight: 500;
`;

export const CustomLabel = styled.span`
  color: black;
  display: inline-block;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 10px;
`;

export const TooltipWrapper = styled.div`
  padding: 10px;
  background: white;
  border: 1px solid #ccc;
`;

export const Payload = styled.div<{ color: string }>`
  margin-bottom: 5px;
  color: ${(props) => props.color};
  display: flex;
  justify-content: space-between;

  .first {
    display: inline-block;
    margin-right: 10px;
  }
`;

const GraphBase: FC<{
  transformer: GTransformer;
  kind: string;
  graphView: string;
  onEventClick?: (
    e: SVGGElement,
    elementIndex: number,
    index: number,
    data: GraphDataBundle
  ) => void;
}> = ({ transformer, kind, graphView, onEventClick }) => {
  const accRevContext = useAccRevContext(); // TODO: remove this hack
  const accDemandContext = useDemandContext();
  const theme = useTheme();

  const curZone = useSelector(selectCurrentZones);
  const curFacility = useSelector(selectCurrentFacility);
  //const events = useSelector((root: ApplicationState) => root.eventData);

  hasFlag(curFacility, FacilityFlag.NoFutureGraphDisplay);
  const events = useSelector(selectAllEventsFromCurrentFacility);
  const curSegment = useSelector(
    (root: ApplicationState) => root.uiState.segments
  );
  const startDate = useSelector(selectUiStateStartDate);
  const endDate = useSelector(selectUiStateEndDate);
  const enableCompare = useSelector(selectUIStateEnableCompare);
  const cache = useSelector(selectCalcCacheData);
  const compCache = useSelector(selectCompCalcCacheData);
  const duration = useSelector(selectUiStateDuration);
  const curPriceViewTypeID = useSelector(selectUiStatePriceTypeViewID);
  const curRev = useSelector(selectUIStateRevView);
  const curCompCache = useSelector(selectCurrentViewCompareDataSet);

  const newCalcStep = useSelector(selectCalcStepLengthMinutes) / 60;
  const cachedPriceSeries = useSelector(selectUIValidPriceTypes);
  const [hasCompare, setHasCompare] = useState(curRev !== 'regular');

  const compareDataKeys: GTCompareKeys =
    curRev === 'currProp'
      ? {
          indicateFuture: false,
          olderName: 'Current',
          olderValueKey: 'toRevenue',
          olderSegValueKey: 'oldRevenue',
          olderAccKey: 'toAccRev',
          newerName: 'Dynamic',
          newerValueKey: 'tnRevenue',
          newerSegValueKey: 'newRevenue',
          newerAccKey: 'tnAccRev',
        }
      : {
          indicateFuture: true,
          olderName: 'Previous year',
          olderValueKey: 'comRevenue',
          olderSegValueKey: 'oldRevenue', // TODO!?
          olderAccKey: 'comAccRev',
          newerName: 'Selected year',
          newerValueKey: 'tdRevenue',
          newerSegValueKey: 'defRevenue',
          newerAccKey: 'tdAccRev',
        };

  const uiDataTS = useSelector(selectUiStateDataUpdateTS);
  const uiCDataTS = useSelector(selectUiStateCDataUpdateTS);
  const cacheTS = cache?.cacheTS ?? 0;
  const cCacheTS = compCache?.cacheTS ?? 0;

  const [graphData, setGraphData] = useState<GraphData>(null as any);
  const [_graphView, setGraphView] = useState<string>(graphView ?? 'bars');
  const [rerender, setRerender] = useState<boolean>(false);

  const getFirstWeekStep = (date: string) => {
    const year = date.substring(0, 4);
    const firstDay = new Date(year + '-01-01');
    const dayOfWeek = firstDay.getDay();
    switch (dayOfWeek) {
      case 0: {
        // Sunday
        return 24 * 1;
      }
      case 1: {
        // Monday
        return 0;
      }
      default: {
        return 24 * (7 - dayOfWeek + 1);
      }
    }
  };

  useEffect(() => {
    setRerender(true);
    setTimeout(() => {
      setGraphView(graphView);
      setTimeout(() => {
        setRerender(false);
      }, 500);
    }, 0);
  }, [graphView]);

  useEffect(() => {
    if (!!graphData) {
      accDemandContext.setGraphData(graphData);
      accRevContext.setGraphData(graphData);
    }
  }, [graphData]);

  useEffect(() => {
    console.log('CACHE', cache);
    if (cache && cache!.data.zones.length > 0) {
      const step = newCalcStep; //calcStep();
      let firstStep;
      switch (duration) {
        case 'weeksInYear': {
          const firstDateInData = cache!.data.zones[0].hours[0].time;
          firstStep = getFirstWeekStep(firstDateInData);
          break;
        }
        case 'month':
        case 'week':
        case 'day':
        default: {
          firstStep = step;
        }
      }

      const zonesForZip = [
        ...cache!.data.zones,
        // append __compare__ to all series names from the compare cache.
        ...(!enableCompare ? [] : compCache?.data?.zones ?? []).map((z) => ({
          ...z,
          series: z.series.map((ser) => ({
            ...ser,
            outID: '__compare__' + ser.outID,
            clientIsForCompare: true,
          })),
        })),
      ];

      const data = dataZip(
        zonesForZip,
        curSegment,
        curPriceViewTypeID,
        step,
        firstStep,
        curZone == null ? [] : curZone.map((z) => z.id),
        curCompCache
      );
      setGraphData(data);
      console.log('CHECKING', zonesForZip);
      setHasCompare(
        zonesForZip.filter((f) => f.series[0].clientIsForCompare).length > 0
      );
    }
  }, [
    cache,
    enableCompare ? compCache : 'comp_disabled',
    newCalcStep,
    curSegment,
    curPriceViewTypeID,
    curZone,
    curRev,
  ]);

  if (rerender) {
    return (
      <MarginWrapper>
        <AspectWrapOuter>
          <AspectWrapInner>
            <GraphLoader
              title={'Not enough data to make prediction'}
              forceError={false}
            />
          </AspectWrapInner>
        </AspectWrapOuter>
      </MarginWrapper>
    );
  }

  if (curZone.length === 1 && hasFlag(curZone[0], ZoneFlag.ForceNoGraph)) {
    return (
      <MarginWrapper>
        <AspectWrapOuter>
          <AspectWrapInner>
            <GraphLoader
              title={'Not enough data to make prediction'}
              forceError={true}
            />
          </AspectWrapInner>
        </AspectWrapOuter>
      </MarginWrapper>
    );
  }

  console.log('graphData', graphData);

  if (
    graphData === null ||
    !cache ||
    (cache.zones === null) !== (curZone === null) ||
    (cache?.zones &&
      curZone &&
      !cache?.zones.find((z) => !!curZone.find((x) => x.id === z.id))) ||
    cache.startDate !== startDate ||
    cache.endDate !== endDate ||
    !cache.data.zones ||
    cache.data.zones.length === 0
  ) {
    console.log('HEYO!');
    const loader = (
      <MarginWrapper>
        <AspectWrapOuter>
          <AspectWrapInner>
            <GraphLoader />
          </AspectWrapInner>
        </AspectWrapOuter>
      </MarginWrapper>
    );
    // console.log(
    //   `GDNULL:${graphData === null} Cache:${cache} CaZon:${cache?.zone} (${
    //     cache?.zoneIdSet
    //   })  CuZon:${curZone} Non-matchingZID:${
    //     cache?.zone && curZone && cache?.zone?.id !== curZone?.id
    //   }`,
    //   loader
    // );
    return loader;
  }
  //return <Wrap>Graph placeholder</Wrap>;

  // Hacky way to move state upwards in component stack, please refactor when there's more time.
  accRevContext.setVisibleDiff(hasCompare);
  const accSeriesID =
    curSegment === 'all'
      ? -1
      : graphData.data[graphData.data.length - 1]?.seriesID?.findIndex(
          (sid) =>
            sid ===
            (curSegment === 'driveup' ? 'revenue_driveup' : 'revenue_booking')
        );
  // console.log(
  //   'Last POINT:',
  //   graphData.data[graphData.data.length - 1],
  //   curSegment,
  //   accSeriesID
  // );
  accRevContext.setAccRev(
    curSegment === 'all'
      ? ((graphData.data[graphData.data?.length - 1]?.[
          compareDataKeys.newerAccKey
        ] ?? -1) as number)
      : 0 |
          (graphData.data[graphData.data?.length - 1]?.seriesAcc[accSeriesID] ??
            -1)
  );
  accRevContext.setOldAccRev(
    (hasCompare
      ? graphData.data[graphData.data.length - 1]?.[
          compareDataKeys.olderAccKey
        ] ?? -1
      : graphData.data[graphData.data.length - 1]?.[
          compareDataKeys.newerAccKey
        ] ?? -1) as number
  );

  accDemandContext.setAccDemand({
    currentAcc: graphData.demandAcc,
    oldAcc: graphData.oldDemandAcc,
    diff: graphData.custAccDiff,
  });

  const getXAxisInterval = (view: string, duration: string) => {
    switch (duration) {
      case 'day': {
        return 23;
      }
      case 'week': {
        if (newCalcStep === 1) return 23;
        else return 0;
      }
      case 'month': {
        return 1;
      }
    }
    return 0;
  };

  const xAxisInterval = getXAxisInterval(transformer.name, duration);

  const tooltipLabelFormatter =
    newCalcStep < 24
      ? (value: string) => moment(value).format('yyyy-MM-DD, HH:00') // value string should include time
      : (value: string) => moment(value).format('yyyy-MM-DD'); // exclude time

  const cpsPT = cachedPriceSeries.find((cps) => cps.id === curPriceViewTypeID);

  const momStartDate = moment.utc(startDate);
  const momEndDate = moment.utc(endDate);

  var _graphData = {
    ...graphData,
    priceType: cpsPT
      ? cpsPT.name + (cpsPT.isAverage ? ' avg' : '')
      : curPriceViewTypeID,
    revenueType: curRev,
    zones: curZone,
    facility: curFacility,
    events: events.filter(
      (ev) =>
        !(
          moment.utc(ev.endDate).isBefore(momStartDate) ||
          moment.utc(ev.startDate).isAfter(momEndDate)
        )
    ),
  } as GraphDataBundle;

  var sum = graphData.data.reduce((acc, curr) => {
    if (kind === 'revenue') return (acc += curr.tdRevenue);
    else return (acc += curr.demand);
  }, 0);

  if (
    curZone.length === 1 &&
    hasFlag(curZone[0], ZoneFlag.LowData) &&
    sum === 0
  ) {
    return (
      <MarginWrapper>
        <AspectWrapOuter>
          <AspectWrapInner>
            <GraphLoader
              title={'Not enough data to make prediction'}
              forceError={true}
            />
          </AspectWrapInner>
        </AspectWrapOuter>
      </MarginWrapper>
    );
  }

  const renderCustomerXTick = (props: any): ReactElement => {
    const { payload, x, y } = props;
    const date = moment(payload.value);

    if (duration === 'weeksInYear' && date.month() === prevDrawnMonthTick) {
      return (
        <text
          x={x}
          y={y + 10}
          textAnchor="middle"
          fill={theme.palette.text.fg}
        />
      );
    }
    prevDrawnMonthTick = date.month();
    if (date.day() === 1) {
      return (
        <text x={x} y={y + 10} textAnchor="middle" fill={theme.palette.text.fg}>
          <tspan fontSize={14}>
            {date.date()}/{date.month() + 1}
          </tspan>
          <tspan fontSize={12} fill={'#8f8d8d'} x={x} y={y + 25}>
            {date.format('ddd')} - v {date.week()}
          </tspan>
        </text>
      );
    }
    return (
      <text x={x} y={y + 10} textAnchor="middle" fill={theme.palette.text.fg}>
        <tspan fontSize={14}>
          {date.date()}/{date.month() + 1}
        </tspan>
        <tspan fontSize={12} fill={'#8f8d8d'} x={x} y={y + 25}>
          {date.format('ddd')}
        </tspan>
      </text>
    );
  };

  const renderTooltip = (props: any) => {
    //console.log(props);
    const specialDay = isSpecialDay(
      moment.utc(props.label).toDate(),
      _graphData
    );
    return (
      <TooltipWrapper>
        <label>{tooltipLabelFormatter(props.label)}</label>
        {props &&
          props.payload &&
          props.payload.map((x: any) => {
            var valueNumber = Number(x.value);

            if (
              x.payload.tempDisabled &&
              -1 == x.name.toLowerCase().indexOf('prices')
            ) {
              return (
                <Payload color={x.color}>
                  <span className={'first'}>{x.name}:</span>
                  <span>Prediction is temporary disabled</span>
                </Payload>
              );
            }
            if (!isNaN(valueNumber)) {
              return (
                <Payload color={x.color} key={x.name}>
                  <span className={'first'}>{x.name}:</span>
                  <span>
                    {addCharToNum(
                      (Math.round(Number(valueNumber) * 10) / 10).toFixed(0),
                      ' '
                    )}
                  </span>
                </Payload>
              );
            }
            return (
              <Payload color={x.color}>
                <span className={'first'}>{x.name}:</span>
                <span>{x.value}</span>
              </Payload>
            );
          })}
        {specialDay.eventExists &&
          specialDay.event!.map((x) => (
            <Payload key={x.dateID} color={'#000'}>
              <span className="first">Event:</span>
              <span>{x.name}</span>
            </Payload>
          ))}
      </TooltipWrapper>
    );
  };

  if (
    hasFlag(curFacility, FacilityFlag.NoFutureGraphDisplay) ||
    (curZone.length === 1 && hasFlag(curZone[0], ZoneFlag.LowData))
  ) {
    var tomorrow = moment
      .utc()
      .startOf('day')
      .add(1, 'day')
      .format('YYYY-MM-DD');
    var iterationValue = {
      defRev: 0,
      newRev: 0,
      oldRev: 0,
      tdRev: 0,
      tnRev: 0,
      toRev: 0,
      demand: 0,
    };
    graphData.data = graphData.data.map((f) => {
      if (f.date >= tomorrow) {
        iterationValue.demand += f.demand;
        return {
          ...f,
          tdAccRev: iterationValue.tdRev,
          tdRevenue: 0,
          tnAccRev: iterationValue.tnRev,
          tnRevenue: 0,
          toAccRev: iterationValue.toRev,
          toRevenue: 0,
          demand: 0,
          comAccRev: iterationValue.oldRev,
          //price: 0,
          tempDisabled: true,
          segments: f.segments.map((seg) => {
            return {
              ...seg,
              demand: 0,
              defRevenue: {
                current: 0,
                accumulated: iterationValue.defRev,
              },
              newRevenue: {
                current: 0,
                accumulated: iterationValue.newRev,
              },
              oldRevenue: {
                current: 0,
                accumulated: iterationValue.oldRev,
              },
            };
          }),
        };
      }
      iterationValue.tdRev = f.tdAccRev;
      iterationValue.tnRev = f.tnAccRev;
      iterationValue.toRev = f.toAccRev;
      iterationValue.oldRev = f.comAccRev;
      f.segments.map((f) => {
        iterationValue.defRev = f.defRevenue.accumulated;
        iterationValue.newRev = f.newRevenue.accumulated;
        iterationValue.oldRev = f.oldRevenue.accumulated;
      });
      return f;
    });
    graphData.demandAcc -= iterationValue.demand;
    _graphData.demandAcc = graphData.demandAcc;
    _graphData.data = graphData.data;
  }

  const { chartData, leftMaxViewValue, rightMaxViewValue } = transformer(
    _graphData,
    curZone !== undefined,
    compareDataKeys,
    _graphView,
    curSegment,
    onEventClick
  );

  let prevDrawnMonthTick = -1000;
  let prevDrawnDayTick = -1000;

  // console.log(`UITS:${uiDataTS} CCTS:${cacheTS}`);
  const hasOccupancy =
    kind === 'demand' &&
    graphData.seriesIDs.filter(
      (f) => f.startsWith('occupancy') || f === 'capacity'
    ).length !== 0;

  console.log(kind, _graphView, kind === 'revenue' || _graphView !== 'line');

  return (
    <MarginWrapper>
      <ResponsiveContainer width="100%" height={600}>
        <ComposedChart
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          data={graphData.data}
        >
          <CartesianGrid stroke={theme.palette.common.lightBorder} />
          {/* Render ReferenceLines for every Monday */}
          {graphData.data.map((entry, index) =>
            moment(entry.date).day() === 1 ? (
              <ReferenceLine
                key={index}
                x={entry.date} // Using date as the x-value, matching the X-axis dataKey
                ifOverflow="extendDomain" // Ensure the line is shown even if it extends beyond the domain
                stroke={theme.palette.common.lightBorder}
                yAxisId="left"
                strokeWidth={5}
              />
            ) : null
          )}
          <XAxis
            textAnchor="start"
            scale="band"
            dataKey="date"
            tick={renderCustomerXTick as any}
            stroke={theme.palette.common.lightBorder}
            interval={xAxisInterval}
          />
          {
            <YAxis
              domain={[0, leftMaxViewValue]}
              yAxisId="left"
              orientation="left"
              tickFormatter={numToSpacedString}
              width={yAxisWidth}
              tick={{ fill: theme.palette.text.fg }}
              stroke={theme.palette.common.lightBorder}
            />
          }
          {(kind === 'revenue' || _graphView !== 'line') && (
            <YAxis
              domain={[0, rightMaxViewValue]}
              yAxisId="right"
              orientation="right"
              tickFormatter={numToSpacedString}
              width={yAxisWidth}
              tick={{ fill: theme.palette.text.fg }}
              stroke={theme.palette.common.lightBorder}
            />
          )}
          {hasOccupancy && _graphView !== 'line' && (
            <YAxis
              domain={[
                0,
                !!graphData.data[0].price
                  ? leftMaxViewValue
                  : rightMaxViewValue,
              ]}
              label={
                !!graphData.data[0].price
                  ? {
                      value: 'Occupancy',
                      angle: -90,
                      position: 'insideLeft',
                    }
                  : undefined
              }
              yAxisId={!!graphData.data[0].price ? 'left_left' : 'right'}
              orientation={!!graphData.data[0].price ? 'left' : 'right'}
              tickFormatter={numToSpacedString}
              width={yAxisWidth}
              tick={{ fill: theme.palette.text.fg }}
              stroke={theme.palette.common.lightBorder}
            />
          )}
          {chartData}
          <Tooltip
            // labelFormatter={tooltipLabelFormatter}
            // formatter={(value: string, name: string, props: any) => {
            //   var valueNumber = Number(value);
            //   if (!isNaN(valueNumber)) {
            //     return (Math.round(Number(valueNumber) * 10) / 10).toString();
            //   }
            //   return value;
            // }}
            content={renderTooltip}
          />
          <Legend
            wrapperStyle={{ paddingTop: 10 }}
            formatter={(value, entry, index) => (
              <CustomLabel>{value}</CustomLabel>
            )}
          />
        </ComposedChart>
      </ResponsiveContainer>
      {(uiDataTS != cacheTS || uiCDataTS != cCacheTS) && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#ffffff',
            opacity: 0.35,
          }}
        >
          <GraphLoader />
        </div>
      )}
    </MarginWrapper>
  );
};

export default GraphBase;
