import * as Actions from './action';
import { ZONESTORE } from './constants';
import { ActionReturns } from '../../util/tshelpers';
import moment from 'moment';
import { PriceProductExport } from 'store/uiSettings/reducer';

export type Zones = Zone[];

export type Rule = {
  id: number;
  type: string;
  info: string;
  displayName: string;
}
export type ZoneRule = {
  rule: Rule // ex Klippning
  value: string; // värde kopplat till regel för zonen
  segment: string;
}

export enum ZoneFlag {
  Electric = 1,
  LowData = 2,
  NoAIPrediction = 3,
  ForceNoGraph = 4,
}

export interface ZoneConfig {
  IsElectric: boolean;
}
export interface Zone {
  id: number;
  name: string;
  sitePath?: string;
  gid: string;

  aggressivity: number;

  selectedPricing: number | null;
  pricings: Pricing[];
  schedules: PriceSchedule[];
  exports: PriceProductExport[];
  zoneFlags?: ZoneFlag[];
  config?: ZoneConfig;
  rules?: ZoneRule[];
  //  events: Event[];
}

export interface PriceSchedule {
  id: number;
  name: string;
  gid: string;
  dZoneID: number;

  previewActivationTimeStamp?: number | null;
  liveActivationTimeStamp?: number | null;
  priceActivations: PriceActivation[];
  pricings: Pricing[];
}

export interface PriceActivation {
  priceID: number;
  fromDate: string;
  toDate: string;
}
export interface Pricing {
  id: number;
  name: string;
  parentZoneId: number;
  selected?: boolean;

  parentScheduleGID?: string;
  parentScheduleID?: number;
  startDate?: string;
  endDate?: null | string;

  // minute min/max pricing levels
  aggressivity: number;

  prices: PricingRange[];
  priceGroups: PricingGroup[];
  priceDiscounts: PricingDiscount[];
  userOpts: PricingOpts;
  sourceSpanGID?: string;
}

export interface PricingScheduleSpan {
  gid: string;
  parentFacilityId: number;
  name: string;
  startDate: string;
  endDate?: string;
  recurringID?: string;
}

export interface PricingOpts {
  occupancy_target?: {
    min?: number;
    max?: number;
  };
  maxCapacity?: number;
  bookingCapacity?: number;
  longStayWeekDiscount?: number;
}

export interface PricingRange {
  id: number;
  segment: 'booking' | 'driveup' | 'surcharge';
  duration: 'hour' | 'day' | 'week';
  /** if true, active flag should be toggleable */
  toggleable: boolean;
  active: boolean;
  min: number;
  max: number;

  groupId?: number | null;
  activationRangeId?: number | null;
}

// Conditions with data ALL need to pass, ie if no data exists for any condition it's an instant pass.
export interface PricingCondition {
  startDays?: number[]; // ordered set of 1-based weekdays when the start time happens (1,2,3,4,5,6,7 are valid values)
  activeDays?: number[]; // ordered set of 1-based weekdays during the timespan where this rule applies (1,2,3,4,5,6,7 are valid values)
  durationDayRange?: [number, number]; // [from-to[ (inkl-excl) range of duration in days
  advanceBookingDayRange?: [number, number]; // [from-to[ (inkl-excl) range of days in advance of booking.
  surchargeGroup?: 'electric'; // surcharge group IDs
}

export interface PricingGroup {
  id: number;
  pricingId: number;
  name: string;
  condition: PricingCondition; // TODO: type this properly later.
}

export interface PricingDiscount {
  id: number;
  pricingId: number;
  name: string;
  segment?: string | null;
  discountPercentage: number;
  condition: PricingCondition; // TODO: type this properly later.
}

const initialState: Zones = [];

for (let zone of initialState) {
  if (zone.pricings && zone.pricings.length > 0 && !zone.selectedPricing)
    zone.selectedPricing = zone.pricings[0].id;
  for (let pricing of zone.pricings) {
    pricing.parentZoneId = zone.id;
  }
}

type Actions = ActionReturns<typeof Actions>;

export function reducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ZONESTORE.ZONE_UPDATE:
      return action.zones;
    case ZONESTORE.ZONE_UPDATE_SCHEDULE:
      return state.map((zn) =>
        zn.id !== action.zoneId
          ? zn
          : {
              ...zn,
              schedules: zn.schedules.map((sch) =>
                sch.gid !== action.scheduleGid ? sch : action.updatedSchedule
              ),
            }
      );
    case ZONESTORE.ZONE_UPDATE_SEASONS:
      return state.map((zn) => {
        if (action.schedule.filter((f) => f.dZoneID === zn.id).length === 0)
          return zn;
        return {
          ...zn,
          schedules: action.schedule.filter((f) => f.dZoneID === zn.id),
        };
      });
    case ZONESTORE.ZONE_APPLY:
      return state.map((zn) =>
        zn.id === action.zoneId ? { ...zn, ...action.changes } : zn
      );
    case ZONESTORE.ZONE_ADD_PRICE: {
      console.log(state, action.pricing);
      return state.map((zn) =>
        zn.id !== action.pricing.parentZoneId
          ? zn // untouched zone
          : {
              // mutated zone
              ...zn,
              pricings:
                // append the new pricing
                [...zn.pricings, action.pricing],
            }
      );
    }
    case ZONESTORE.ZONE_MOD_PRICE: {
      return state.map((zn) =>
        zn.id !== action.pricing.parentZoneId
          ? zn // untouched zone
          : {
              // mutated zone
              ...zn,
              pricings: zn.pricings.map(
                // return the modified pricing if it's the updated one, otherwise return what exists.
                (pr) => (pr.id === action.pricing.id ? action.pricing : pr)
              ),
            }
      );
    }
    case ZONESTORE.ZONE_REM_PRICE: {
      return state.map((zn) =>
        zn.id !== action.zoneId
          ? zn // untouched zone
          : {
              // mutated zone
              ...zn,
              // filter out the specified pricing id.
              pricings: zn.pricings.filter((pr) => pr.id !== action.pricingId),
            }
      );
    }
  }
  return state;
}
