import moment from 'moment';
import React from 'react';
import { Bar, Cell, Line } from 'recharts';
import { GTransformer } from './defs';

import {
  findGraphLevel,
  firstToUpper,
  isSpecialDay,
  roundUpMax,
} from './GraphData';

export const npricingTransformer: GTransformer = (data) => {
  const maxCurrent = data.data.reduce(
    (pre, cur) => Math.max(pre, cur.tnRevenue),
    0
  );

  const maxPri = data.data.reduce((pre, cur) => Math.max(pre, cur.price), 0);

  const leftMaxViewValue = findGraphLevel(maxCurrent);
  const rightMaxViewValue = findGraphLevel(maxPri);

  const chartData = (() => {
    return [
      data.bookings && (
        <Bar
          yAxisId="left"
          dataKey="bnRevenue"
          name="Booking revenue"
          stackId="a"
          fill="#8bcfce"
        >
          {data.data.map((ent, idx) => {
            const special = isSpecialDay(
              moment.utc(ent.odate).toDate(),
              data
            ).eventExists;

            return <Cell fill={special ? '#8cbad1' : '#8bcfce'} />;
          })}
        </Bar>
      ),
      data.driveups && (
        <Bar
          yAxisId="left"
          dataKey="dnRevenue"
          name="Driveup revenue"
          stackId="a"
          fill={'#66b2b0'}
        >
          {data.data.map((ent, idx) => {
            const special = isSpecialDay(
              moment.utc(ent.odate).toDate(),
              data
            ).eventExists;

            return <Cell fill={special ? '#6594b2' : '#66b2b0'} />;
          })}
        </Bar>
      ),

      !data.driveups !== !data.bookings && (
        <Line
          name={firstToUpper(data.priceType + ` prices`)}
          yAxisId="right"
          type="linear"
          dataKey="price"
          stroke="#3e8a88"
          strokeWidth={2}
          dot={{ fill: '#3e8a88', strokeWidth: 0 }}
          key="price"
        ></Line>
      ),
    ];
  })();

  return { chartData, leftMaxViewValue, rightMaxViewValue };
};
