import { ReactTable } from '@bitio/react-components';
import Button from 'components/input/Button';
import React from 'react';
import { Zones } from 'store/zone/reducer';
import { styled } from 'styled-components';
import { useConfigContext } from '../contexts/configurationContext';

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;
const ConfigZones: React.FC<Zones> = () => {
  const { zones } = useConfigContext();
  return (
    <Wrapper>
      <ReactTable
        data={zones}
        columns={[
          { header: 'Zone Name', accessorKey: 'name' },
          { header: 'Applied Rule', accessorFn: (row) => row.rules?.[0] },
          {
            header: 'Edit',
            accessorKey: 'id',
            cell: (props) => (
              <Button onClick={() => props.row.original.id}>Edit</Button>
            ),
          },
        ]}
      />
    </Wrapper>
  );
};

export default ConfigZones;

