import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSpecialZonePickerValues } from 'store/ui/uiutil';
import { getDevEnvironment } from 'store/uiSettings/selectors';
import { Zones } from 'store/zone/reducer';
import { selectAvailableZones } from 'store/zone/selector';

export type Rule = {
  id: number;
  name: string;
  facilityId: number;
};

export type ZoneRule = {
  id: number;
  dynZoneId: number;
  configurationId: number; // Rule id
  value: string;
  groupId?: number;
  segment: string; // Dropdown driveup /booking
};

export type ZoneGroup = {
  name: string;
  id: number;
  zoneIds: string[];
  rules: Rule[];
  facilityId: number;
};

export type ConfigContextType = {
  groups: ZoneGroup[];
  zones: Zones;
  rules: Rule[];
  setGroups: React.Dispatch<React.SetStateAction<ZoneGroup[]>>;
  setZones: (zones: Zones) => void;
  setRules: (rules: Rule[]) => void;
  setFacilityId: (facilityId: string) => void;
  editGroup: (updatedGroup: ZoneGroup) => void;
  createGroup: (newGroup: ZoneGroup) => void;
  deleteGroup: (groupId: number) => void;
};
// Fetch zones data from API

export const ConfigContext = React.createContext<ConfigContextType>(null!);

export const ConfigContextProvider: React.FC = ({ children }) => {
  const availableZones = useSelector(selectAvailableZones);
  const [groups, setGroups] = useState<ZoneGroup[]>([]);
  const [zones, setZones] = useState<Zones>([]);
  const [rules, setRules] = useState<Rule[]>([]);
  const [facilityId, setFacilityId] = useState<string>('');
  var isDevelopment = useSelector(getDevEnvironment);

  // Fetch groups
  const fetchGroups = async (facilityId: string) => {
    debugger;
    if(!isDevelopment) {
      setGroups(getSpecialZonePickerValues(facilityId));
      return;
      }
    try {
      const response = await fetch(
        `/api/Configuration/${facilityId}/get/groups`
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch groups: ${response.statusText}`);
      }

      const data: ZoneGroup[] = await response.json();
      setGroups(data);
    } catch (error) {
      console.error('Error fetching groups:', error);
      alert('Failed to fetch group data. Please try again later.');
    }
  };

  // Fetch rules
  const fetchRules = async () => {
    try {
      const response = await fetch(`/api/Configuration/get/rules`);

      if (!response.ok) {
        throw new Error(`Failed to fetch rules: ${response.statusText}`);
      }

      const data: Rule[] = await response.json();
      setRules(data);
    } catch (error) {
      console.error('Error fetching groups:', error);
      alert('Failed to fetch group data. Please try again later.');
    }
  };
  // Create new group
  const createGroup = async (newGroup: ZoneGroup) => {
    try {
      const response = await fetch(
        `/api/Configuration/${facilityId}/create/group`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newGroup),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to create group: ${response.statusText}`);
      }

      const createdGroup: ZoneGroup = await response.json();
      setGroups((prevGroups) => [...prevGroups, createdGroup]);
      console.log(newGroup, 'Test Create');
    } catch (error) {
      console.error('Error creating group:', error);
      alert('Failed to create the group. Please try again later.');
    }
  };

  // Edit group
  const editGroup = async (updatedGroup: ZoneGroup) => {
    console.log(facilityId)
    try {
      await fetch(`/api/Configuration/${facilityId}/edit/group`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedGroup),
      });

      setGroups((prevGroups) =>
        prevGroups.map(
          (group) => (group.id === updatedGroup.id ? updatedGroup : group),
        )
      );
    } catch (error) {
      console.error('Error updating group:', error);
    }
  };
  // Delete group
  const deleteGroup = async (groupId: number) => {
    try {
      const response = await fetch(
        `/api/Configuration/${facilityId}/delete/group/${groupId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const updatedGroups: ZoneGroup[] = await response.json();
      setGroups(updatedGroups);
    } catch (error) {
      console.error('Fail to delete!', error);
    }
  };

  useEffect(() => {
    if (!!facilityId) {
      const filtered = availableZones.filter((zone) => zone.sitePath === facilityId);
      setZones(filtered);
      fetchGroups(facilityId);
      fetchRules();
    }
  }, [facilityId, availableZones]);
  return (
    <ConfigContext.Provider
      value={{
        groups,
        zones,
        rules,
        setGroups,
        setZones,
        setRules,
        setFacilityId,
        editGroup,
        createGroup,
        deleteGroup,
        
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = () => useContext(ConfigContext);

