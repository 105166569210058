import Modal from '../../components/Modal';
import PriceProductEditor from '../../containers/PriceProductEditor';
import usePricingEditorReducer from '../../containers/PriceProductEditor/usePricingEditorState';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PriceSchedule,
  Pricing,
  PricingRange,
  Zone,
} from '../../store/zone/reducer';
import { ApplicationState } from '../../store/definitions';
import { PriceV3Api } from 'constants/api-fetch';
import { clearCacheUpdate } from 'store/calcCache/action';
import { CardType } from 'components/Seasons';
import { getSameDayNextYear } from 'util/date';
import moment from 'moment';
import { updateSchedule, zoneStoreApply } from 'store/zone/action';

const makePricingRange = (
  id: number,
  duration: PricingRange['duration'],
  segment: PricingRange['segment']
): PricingRange => ({
  id,
  active: false,
  toggleable: true,
  duration,
  max: 0,
  min: 0,
  segment,
});

interface Props {
  priceId: number;
  zone: Zone;
  onModPricing(pricing: Pricing |PriceSchedule): void;
  cardType: CardType;
  readOnly?: boolean;
  duplicate?: boolean;
  multiplePricings?: number[];
}

const ensurePrices = (prices: PricingRange[]) => {
  const reqds = [
    { dur: 'day', seg: 'driveup' },
    { dur: 'hour', seg: 'driveup' },
    { dur: 'week', seg: 'driveup' },
    { dur: 'day', seg: 'booking' },
    { dur: 'hour', seg: 'booking' },
    { dur: 'week', seg: 'booking' },
  ] as const;
  for (let i = 0; i < reqds.length; i++) {
    const req = reqds[i];
    if (prices.find((pr) => pr.duration === req.dur && pr.segment === req.seg))
      continue;
    prices = [...prices, makePricingRange(-1 - i, req.dur, req.seg)];
  }
  return prices;
};

const ModPriceProductModal: FC<Props> = ({
  priceId,
  zone,
  onModPricing,
  cardType,
  readOnly = false,
  duplicate = false,
  multiplePricings = [],
}) => {
  // assumes that this modal can't be opened if a zone isn't selected
  // let curZone = useSelector(selectCurrentZone);
  // let _zone = zone;
  // if (!_zone) {
  //   _zone = curZone;
  //   throw new Error('Old bad logic?');
  // }

  const inputPricing =
    zone!.pricings.find((zp) => zp.id === priceId) ??
    zone!.schedules
      .map((sch) => sch.pricings.find((pri) => pri.id === priceId))
      .filter((pri) => !!pri)[0]!;

  console.log('INPUT', inputPricing);

  var startDate = undefined;
  var endDate = undefined;

  const duplicateModulation = (pricing: Pricing) => {
    if (duplicate) {
      pricing.id = 0;
      pricing.startDate = getSameDayNextYear(moment(pricing.startDate)).format(
        'YYYY-MM-DD'
      );
      pricing.endDate =
        pricing.endDate === undefined || pricing.endDate === null
          ? null
          : getSameDayNextYear(moment(pricing.endDate)).format('YYYY-MM-DD');
      return pricing;
    } else return pricing;
  };

  const priceProductEditorReducer = usePricingEditorReducer(
    duplicateModulation({ ...inputPricing })
    /*
    {
    name: '',
    aggressivity: 0,
    id: '',
    parentZoneId: zone.id,
    prices: [
      makePricingRange('-1', 'day', 'driveup'),
      makePricingRange('-2', 'hour', 'driveup'),
      makePricingRange('-3', 'week', 'driveup'),
      makePricingRange('-4', 'day', 'booking'),
      makePricingRange('-5', 'hour', 'booking'),
      makePricingRange('-6', 'week', 'booking'),
    ],
  }*/
  );
  if (!priceProductEditorReducer[0].userOpts) {
    priceProductEditorReducer[0].userOpts = {};
  }

  const dispatch = useDispatch();

  var buttons = [];
  if (duplicate) {
    buttons.push({
      disabled: !(
        priceProductEditorReducer[0].name.length > 0 &&
        priceProductEditorReducer[0].prices.find((pricing) => pricing.active)
      ),
      label: 'Add',
      onClick: () => {
        var api = new PriceV3Api();
        api
          .post('create/scheduledpricing', JSON.stringify(priceProductEditorReducer[0]))
          .then((pricing: PriceSchedule) => {
            onModPricing(pricing);
          });
      },
    });
  } else if (!readOnly) {
    buttons.push({
      disabled: !(
        priceProductEditorReducer[0].name.length > 0 &&
        priceProductEditorReducer[0].prices.find((pricing) => pricing.active)
      ),
      label: 'Change',
      onClick: () => {
        var api = new PriceV3Api();
        api
          .post(
            'update/pricing',
            JSON.stringify({
              ...priceProductEditorReducer[0],
              zones: multiplePricings,
            })
          )
          .then((result: PriceSchedule[]) => {
            dispatch(updateSchedule(result));
          });
      },
    });
    var sch = zone!.schedules.find(
      (c) => !!c.pricings.find((p) => p.id === priceId)
    );
    var priceActiviation = sch!.priceActivations.find(
      (pa) => pa.priceID === priceId
    );
    console.log(sch, priceActiviation);
    startDate = priceActiviation!.fromDate;
    endDate = priceActiviation!.toDate;
  }

  return (
    <Modal title="Change scheduled price" buttons={buttons}>
      <PriceProductEditor
        zone={zone}
        priceProductEditorReducer={priceProductEditorReducer}
        cardType={cardType}
        readOnly={readOnly}
        visualStartDate={startDate}
        visualEndDate={endDate}
      />
    </Modal>
  );
};

export default ModPriceProductModal;
