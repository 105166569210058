import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import {
  useConfigContext,
  ZoneGroup,
  Rule,
} from '../contexts/configurationContext';
import Select from 'components/input/Select';
import Label from 'components/input/Label';
import styled from 'styled-components';
import Input from 'components/input/Input';
import ScrollableCheckbox from './ScrollableCheckbox';

interface GroupModalProps {
  editableGroup?: ZoneGroup;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupModal: React.FC<GroupModalProps> = ({ editableGroup }) => {
  const { zones, rules, editGroup, createGroup } = useConfigContext();
  const [groupName, setGroupName] = useState<string>('');
  const [selectedZones, setSelectedZones] = useState<string[]>([]);
  const [selectedRule, setSelectedRule] = useState<Rule | null>(null);

  useEffect(() => {
    if (editableGroup) {
      console.log('What is the facility id', editableGroup.facilityId);
      setGroupName(editableGroup.name);
      setSelectedZones(editableGroup.zoneIds);
      setSelectedRule(editableGroup.rules[0] || null);
    }
  }, [editableGroup]);

  const handleSubmit = () => {
    if (!groupName || selectedZones.length === 0) {
      alert('Please fill all fields');
      return;
    }

    const group: ZoneGroup = {
      name: groupName,
      id: editableGroup ? editableGroup.id : 0,
      zoneIds: selectedZones,
      rules: selectedRule ? [selectedRule] : [],
      facilityId: editableGroup?.facilityId || 0,
    };

    if (editableGroup) {
      editGroup(group);
    } else {
      createGroup(group);
    }
  };

  return (
    <Modal
      title={editableGroup ? 'Edit Group' : 'Create New Group'}
      buttons={[
        {
          // disabled:
          //   groupName.length === 0 ||
          //   selectedZones.length === 0 ||
          //   !selectedRule,
          label: editableGroup ? 'Save Changes' : 'Create',
          onClick: handleSubmit,
        },
      ]}
    >
      <Wrapper>
        <Label label="Group Name">
          <Input
            type="text"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Enter group name"
          />
        </Label>
        <ScrollableCheckbox
          zones={zones.map((zone) => ({
            gid: zone.gid,
            name: zone.name,
          }))}
          selectedZoneIds={selectedZones}
          onChange={setSelectedZones}
        />

        <Label label="Apply Rule">
          <Select
            value={selectedRule ? selectedRule.id : ''}
            onChange={(e) => {
              const selectedId = parseInt(e.target.value, 10);
              const rule = rules.find((r) => r.id === selectedId) || null;
              setSelectedRule(rule);
            }}
          >
            <option value={''}>Select a rule</option>
            {rules.map((rule) => (
              <option key={rule.id} value={rule.id}>
                {`Rule ${rule.name}`}
              </option>
            ))}
          </Select>
        </Label>
      </Wrapper>
    </Modal>
  );
};

export default GroupModal;

// TODO:
// Trycker add rule - action
// Du får en tabell med 3 columner
// Där första är en dropdown list över regler (RULES fron be)
// Andra är en dropdown lista över segments
// Sista är ett number input (VAlUE)
// Save or edit knapp //

