import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import SeasonCardList, { CardType, SeasonCardType } from 'components/Seasons';
import { PriceSchedule, Pricing, Zone, PricingRange } from 'store/zone/reducer';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import {
  getDaysBetweenDates,
  getEndOfYear,
  getMonthsBetweenDates,
  getStartOfMonth,
  getStartOfYear,
} from 'util/date';
import moment from 'moment';
import { BlockMode, getBlockMode } from 'util/schedule';
import Select from 'components/input/Select';
import { convertToFirstUpperCase } from 'store/ui/uiutil';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateSchedule,
  zoneStoreModPrice,
  zoneUpdateSchedule,
} from 'store/zone/action';
import { PriceV3Api } from 'constants/api-fetch';
import useModalStack from 'components/Modal/useModalStack';
import AddPriceProductModal from 'pages/Pricing/AddPriceProductModal';
import Label from 'components/input/Label';
import { IoCaretDown, IoCaretForward } from 'react-icons/io5';
import {
  DAY_TO_PIXEL_MULTIPLIER,
  DateBlockProps,
  MARGIN,
  MAX_ZOOM,
  MIN_ZOOM,
  PADDING_AND_BORDER,
  SegmentOptions,
  ZoneSchemaContainer,
} from './contants';
import {
  BackgroundSpan,
  Block,
  BlockContent,
  BlockRow,
  Content,
  ContentWrapper,
  DateBlockWrapper,
  Indicator,
  IndicatorWrapper,
  MenuContainer,
  MonthHeader,
  Row,
  SeasonRow,
  StickyWrapper,
  Title,
  TitleBlock,
  TitleRow,
  Wrapper,
  WrapperBackground,
  ZoomButton,
} from './components';
import Button, { ButtonColors } from 'components/input/Button';
import { FaClock, FaLeaf } from 'react-icons/fa';
import { hasEditRights } from 'store/uiSettings/selectors';
import ConfirmRemove from 'components/Modal/confirmRemove';
import Loader from 'components/Loader';
import ModPriceProductModal from 'pages/Pricing/ModPriceProductModal';

const DateBlock: React.FC<DateBlockProps> = ({
  blockLists,
  zones,
  multipleEdit,
  onSelectChange,
  toggleMultipleEdit,
}) => {
  const [segment, setSegment] = useState<PricingRange['segment'] | undefined>();
  const [segments, setSegments] = useState<PricingRange['segment'][]>([]);
  const [segmentOptions, setSegmentOptions] = useState<SegmentOptions[]>([]);
  const [selectedEditBlock, setSelectedEditBlocks] = useState<number[]>([]);
  const [activeSegmentOptions, setActiveSegmentOptions] =
    useState<SegmentOptions>();
  const [fetching, isFetching] = useState<boolean>(false);
  const dispatch = useDispatch();
  const modalStack = useModalStack();

  const _firstDate = blockLists.reduce((acc, curr) => {
    if (curr.dateList.length === 0) return acc;
    var date = curr.dateList.sort(
      (a, b) => a.from.getTime() - b.from.getTime()
    )[0].from;
    if (!acc) {
      return date;
    } else if (acc!.getTime() > date.getTime()) {
      return date;
    }
    return acc;
  }, null as Date | null);

  //create like _firstDate but the highest date occuring in to date parameter
  const _endDate = blockLists.reduce((acc, curr) => {
    if (curr.dateList.length === 0) return acc;
    var date = curr.dateList.sort((a, b) => b.to.getTime() - a.to.getTime())[0]
      .to;
    if (!acc) {
      return date;
    } else if (acc!.getTime() < date.getTime()) {
      return date;
    }
    return acc;
  }, null as Date | null);

  const [startDate, setStartDate] = useState<Date | null>(
    _firstDate === null ? null : getStartOfMonth(_firstDate)
  );
  const [endDate, setEndDate] = useState<Date | null>(
    _endDate === null ? null : getStartOfMonth(_endDate)
  );

  const [zoom, setZoom] = useState<number>(DAY_TO_PIXEL_MULTIPLIER);
  const [selectedSeasonCards, setSelectedSeasonCards] = useState<{
    [key: string]: SeasonCardType[];
  }>({});
  const [selectedBlocks, setSelectedBlocks] = useState<
    { zone: string; id: string }[]
  >([]);
  const [activeZones, setActiveZones] = useState<string[]>([]);
  const [maxWidth, setMaxWidth] = useState<number>(0);
  const contentWrapperRef = useRef<HTMLDivElement>(null); // Ref for ContentWrapper
  const firstPresentBlockRef = useRef<HTMLDivElement>(null);
  const canEdit = useSelector(hasEditRights);

  const handleBlockClick = (obj: ZoneSchemaContainer, id: string) => {
    var contentWrapper = document.getElementById('content-wrapper');
    if (!!contentWrapper) {
      var scrollEvent = new Event('scroll');
      contentWrapper!.dispatchEvent(scrollEvent);
    }

    if (!activeZones.includes(obj.title)) {
      // Zone inactive, activate the picked timespan
      const index = obj.dateList.findIndex((date) => date.id === id);
      if (index !== -1) {
        const indexArr = [index - 1, index, index + 1].filter(
          (f) => f >= 0 && f < obj.dateList.length
        );
        setSelectedSeasonCards({
          ...selectedSeasonCards,
          [obj.title]: obj.dateList
            .filter((_, index) => indexArr.includes(index))
            .map((date) => ({
              ...date,
              mode: getBlockMode(date),
              selected: id === date.id,
              zone: obj.zone,
              priceId: date.priceProduct.id,
              gid: date.id,
              sourceGid: date.priceProduct.sourceSpanGID,
            })),
        });
      }
      setActiveZones([...activeZones, obj.title]);
      setSelectedBlocks([...selectedBlocks, { zone: obj.title, id }]);
    } else if (
      selectedBlocks.find(
        (selBlock) => selBlock.zone === obj.title && selBlock.id !== id
      )
    ) {
      // Zone active already, but another block was selected

      const index = obj.dateList.findIndex((date) => date.id === id);
      if (index === -1) return;
      const indexArr = [index - 1, index, index + 1].filter(
        (f) => f >= 0 && f < obj.dateList.length
      );
      setSelectedBlocks([
        ...selectedBlocks.filter((sb) => sb.zone !== obj.title),
        { zone: obj.title, id },
      ]);
      setSelectedSeasonCards({
        ...selectedSeasonCards,
        [obj.title]: obj.dateList
          .filter((_, index) => indexArr.includes(index))
          .map((date) => ({
            ...date,
            mode: getBlockMode(date),
            selected: id === date.id,
            zone: obj.zone,
            priceId: date.priceProduct.id,
            gid: date.id,
            sourceGid: date.priceProduct.sourceSpanGID,
          })),
      });
      setTimeout(() => {
        const element = document.getElementById(`block_${id}`);
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }, 0);
    } else {
      // Zone active and the same block selected, close this row
      setSelectedBlocks(
        [...selectedBlocks].filter((block) => block.zone !== obj.title)
      );
      handleCloseSeasonCardRow(obj);
    }
  };

  const handleCloseSeasonCardRow = (obj: ZoneSchemaContainer) => {
    setActiveZones(activeZones.filter((zone) => zone !== obj.title));
    setSelectedSeasonCards(
      Object.keys(selectedSeasonCards).reduce((acc, curr) => {
        if (curr === obj.title) {
          return acc;
        }
        return {
          ...acc,
          [curr]: selectedSeasonCards[curr],
        };
      }, {})
    );
  };

  const handleScroll = (_: any) => {
    var parent = contentWrapperRef!.current!;
    var children = document.getElementsByClassName('season-wrapper');
    for (var i = 0; i < children.length; i++) {
      var child = children[i] as HTMLDivElement;
      child.style.marginLeft = parent.scrollLeft + 'px';
    }
  };

  useEffect(() => {
    var width = document.getElementById('content-wrapper')?.offsetWidth;
    setMaxWidth(Number(width));

    var activeSegments = blockLists.reduce((acc, curr) => {
      var segments = curr.dateList.flatMap((date) =>
        date.priceProduct.prices.flatMap((price) => price.segment)
      );
      acc = [...acc, ...segments];
      return acc.filter((v, i, a) => a.indexOf(v) === i);
    }, [] as PricingRange['segment'][]);
    setSegments(activeSegments);
  }, []);

  useEffect(() => {
    setActiveSegmentOptions(undefined);
    if (!!segment) {
      var segmentChoices = blockLists.reduce((acc, curr) => {
        curr.dateList.forEach((date) => {
          var prices = date.priceProduct.prices.filter(
            (price) => price.segment === segment
          );
          prices.forEach((price) => {
            if (!!price.groupId) {
              var group = date.priceProduct.priceGroups.find(
                (group) => group.id === price.groupId
              );
              if (group !== undefined) {
                var title = convertToFirstUpperCase(group.name);
                var elem = acc.find((a) => a.title === title);
                if (elem) {
                  elem.id.push(price.id);
                  elem.groupId!.push(price.groupId);
                } else {
                  acc.push({
                    title: convertToFirstUpperCase(group.name),
                    id: [price.id],
                    groupId: [price.groupId],
                  });
                }
              }
            } else {
              var title = convertToFirstUpperCase(price.duration);
              var elem = acc.find((a) => a.title === title);
              if (elem) {
                elem.id.push(price.id);
              } else {
                acc.push({
                  title: convertToFirstUpperCase(price.duration),
                  id: [price.id],
                });
              }
            }
          });
        });
        return acc;
      }, [] as SegmentOptions[]);
      segmentChoices = [
        { title: 'Select duration', id: [0] },
        ...segmentChoices,
      ];
      setSegmentOptions(segmentChoices);
    }
  }, [segment]);

  useEffect(() => {
    if (
      contentWrapperRef.current &&
      firstPresentBlockRef &&
      firstPresentBlockRef!.current!
    ) {
      const contentWrapper = contentWrapperRef.current;
      const firstPresentBlock = firstPresentBlockRef!.current!;

      // Calculate the center position of the content wrapper
      const wrapperCenter = contentWrapper.offsetWidth / 2;

      // Calculate the left position of the first present block
      const blockLeft = firstPresentBlock.offsetLeft;

      // Calculate the scroll position to center the first present block
      const scrollPosition =
        blockLeft - wrapperCenter + firstPresentBlock.offsetWidth / 2;

      // Scroll to the first present block and center it
      contentWrapper.scrollTo({ left: scrollPosition, behavior: 'smooth' });
    }
  }, [firstPresentBlockRef, contentWrapperRef]);

  const renderBlocks = () => {
    const rows: React.ReactNode[] = [];
    let totalWidth = 0;

    // Loop through each element with title in blockLists
    blockLists.forEach((element, rowIndex) => {
      totalWidth = 0;
      const { title, dateList } = element;
      const blocks: React.ReactNode[] = [];
      let previousToDate = startDate ?? element.dateList[0].from; // Initialize with the start of the year
      // Ref for the first block with Mode "present"

      // Loop through each date object in the date list
      dateList
        .sort((a, b) => a.from.getTime() - b.from.getTime())
        .forEach((dateObj, index) => {
          const startDate = dateObj.from;
          const endDate = dateObj.to;
          const duration =
            (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
          const width = Math.round(duration) * zoom; // 3 pixels per day
          const daysFromPrevious = Math.floor(
            (startDate.getTime() - previousToDate.getTime()) /
              (1000 * 3600 * 24)
          );
          const marginLeft = daysFromPrevious * zoom;

          var adjustedMargin = 0;
          if (!!marginLeft && marginLeft > adjustedMargin) {
            adjustedMargin = marginLeft;
          }

          const mode = getBlockMode(dateObj);

          let blockTitle = dateObj.priceProduct.name;
          if (!!segment && !!activeSegmentOptions) {
            var prices = dateObj.priceProduct.prices.filter(
              (price) => price.segment === segment
            );
            if (prices.length !== 0) {
              var { id, groupId } = activeSegmentOptions!;
              var price = prices.find(
                (f) =>
                  id.includes(f.id) &&
                  (!f.groupId || (!!groupId && groupId.includes(f.groupId)))
              );
              if (!!price) {
                blockTitle = `${price.min} - ${price.max}`;
              }
            }
          }

          var months = getMonthsBetweenDates(startDate, endDate) + 1;

          const _width = width;

          blocks.push(
            <Block
              id={`block_${dateObj.id}`}
              ref={mode === BlockMode.PRESENT ? firstPresentBlockRef : null} // Set the ref for the first block with Mode "present"
              mode={mode}
              key={`block_${dateObj.id}-at-index-${index}`}
              width={_width}
              marginLeft={adjustedMargin}
              onClick={() => handleBlockClick(element, dateObj.id)}
              selected={
                selectedBlocks.some((b) => b.id === dateObj.id) || false
              }
              title={blockTitle}
            >
              <BlockContent
                ref={mode === BlockMode.PRESENT ? firstPresentBlockRef : null}
              >
                {!multipleEdit && (
                  <div style={{ marginRight: '5px' }}>
                    {dateObj.priceProduct.sourceSpanGID ? (
                      <FaLeaf size={20} />
                    ) : (
                      <FaClock size={20} />
                    )}
                  </div>
                )}
                {multipleEdit && (
                  <input
                    style={{ marginRight: '12px' }}
                    type="checkbox"
                    checked={selectedEditBlock.some(
                      (b) => b === dateObj.priceProduct.id
                    )}
                    onChange={(evt) => {
                      if (evt.target.checked) {
                        setSelectedEditBlocks([
                          ...selectedEditBlock,
                          dateObj.priceProduct.id,
                        ]);
                      } else {
                        setSelectedEditBlocks(
                          selectedEditBlock.filter(
                            (b) => b !== dateObj.priceProduct.id
                          )
                        );
                      }
                    }}
                  />
                )}
                {blockTitle}
              </BlockContent>
            </Block>
          );

          totalWidth += _width;
          previousToDate = endDate;
        });

      // Add a row with the blocks and title for the current element
      const row = <BlockRow key={`Block-Row-${rowIndex}`}>{blocks}</BlockRow>;

      rows.push(row);

      // Check if this row contains the selected date objects
      const isSelectedRow = !!selectedSeasonCards[title];

      // If the row is selected, render the SeasonCard row below it
      const seasonCardRow = (
        <StickyWrapper
          className="season-wrapper"
          key={`${rowIndex}-season-card-row-wrapper`}
        >
          <SeasonRow
            key={`${rowIndex}-season-card-row`}
            expanded={isSelectedRow}
          >
            <SeasonCardList
              segment={segment ?? 'booking'}
              maxWidth={maxWidth}
              seasonCards={selectedSeasonCards[title] ?? []}
              handleBlockClick={(id) => {
                handleBlockClick(element, id);
              }}
            />
          </SeasonRow>
        </StickyWrapper>
      );
      rows.push(seasonCardRow);
    });

    // Generate month headers with empty title
    var currentMonthStart =
      startDate ??
      blockLists.reduce((acc, curr) => {
        if (curr.dateList.length === 0) return acc;
        return curr.dateList[0].from < acc ? curr.dateList[0].from : acc;
      }, getStartOfYear());

    let endMonth =
      endDate ??
      blockLists.reduce((acc, curr) => {
        if (curr.dateList.length === 0) return acc;
        return curr.dateList[curr.dateList.length - 1].to > acc
          ? curr.dateList[curr.dateList.length - 1].to
          : acc;
      }, getEndOfYear());

    endMonth = moment(endMonth).add(1, 'months').toDate();
    var monthsBetween = getMonthsBetweenDates(currentMonthStart, endMonth);

    var daysBetween = getDaysBetweenDates(currentMonthStart, endMonth);
    const monthHeaders: React.ReactNode[] = [];
    const monthWidths: number[] = [];
    for (let i = 0; i < monthsBetween; i++) {
      const currentMonth = currentMonthStart.getMonth();

      var current = moment(currentMonthStart.toDateString()).add(i, 'months');
      var nextMonth = moment(currentMonthStart.toDateString()).add(
        i + 1,
        'months'
      );

      const monthDuration =
        (new Date(nextMonth.year(), nextMonth.month(), 1).getTime() -
          new Date(current.year(), current.month()).getTime()) /
        (1000 * 3600 * 24);
      const monthWidth = Math.round(monthDuration) * zoom; // 3 pixels per day
      monthWidths.push(monthWidth);
      monthHeaders.push(
        <MonthHeader key={`MonthHeader-${i}`} width={monthWidth}>
          {current.toDate().toLocaleDateString('en-US', { month: 'short' })}
        </MonthHeader>
      );
    }

    const wrapperWidth = Math.max(daysBetween * zoom);

    return (
      <>
        <WrapperBackground>
          {monthWidths.map((x, index) => (
            <BackgroundSpan width={x} key={`bg-span-${index}`} />
          ))}
        </WrapperBackground>
        <Row>{monthHeaders}</Row>
        <Wrapper width={wrapperWidth}>{rows}</Wrapper>
      </>
    );
  };

  const soloZone = zones.length === 1;

  var elem = document.getElementById('content-wrapper');
  if (!!elem) {
    setTimeout(() => {
      handleScroll(1);
    }, 0);
  }

  return (
    <DateBlockWrapper>
      <MenuContainer>
        {multipleEdit && (
          <>
            <Button
              onClick={() => {
                modalStack.push(
                  <ModPriceProductModal
                    priceId={selectedEditBlock[0]}
                    zone={zones[0]}
                    onModPricing={(pricing: Pricing) => {
                      dispatch(zoneStoreModPrice(pricing));
                    }}
                    multiplePricings={selectedEditBlock}
                    cardType={CardType.MULTIPLE}
                  />
                );
              }}
            >
              Edit selected
            </Button>
            <Button
              color={ButtonColors.remove}
              onClick={() => {
                toggleMultipleEdit();
                setSelectedEditBlocks([]);
              }}
            >
              Abort
            </Button>
          </>
        )}

        <Select
          value={segment}
          onChange={(evt) => {
            var value = evt.target.value as PricingRange['segment'];
            setSegment(value);
          }}
        >
          <option value="">Select a segment</option>
          {segments.map((segment) => (
            <option key={segment} value={segment}>
              {convertToFirstUpperCase(segment)}
            </option>
          ))}
        </Select>

        <Select
          disabled={!segment}
          value={!!activeSegmentOptions ? activeSegmentOptions.title : ''}
          onChange={(evt) => {
            var selectedSegmentOption = segmentOptions.find(
              (x) => x.title === evt.target.value
            );
            setActiveSegmentOptions(selectedSegmentOption);
          }}
        >
          {!segment && <option value="">Select duration</option>}
          {segmentOptions.map((option) => (
            <option key={option.title} value={option.title}>
              {option.title}
            </option>
          ))}
        </Select>
        <ZoomButton
          onClick={() => {
            if (MAX_ZOOM == zoom) return;
            setZoom(zoom + 1);
          }}
        >
          <AiOutlineZoomIn />
        </ZoomButton>
        <ZoomButton
          onClick={() => {
            if (zoom == MIN_ZOOM) {
              return;
            }
            setZoom(zoom - 1);
          }}
        >
          <AiOutlineZoomOut />
        </ZoomButton>
      </MenuContainer>
      <Content>
        <TitleBlock>
          <Row style={{ fontSize: '12px' }}>
            <Indicator color="green" /> = Live,
            <Indicator color="orange" /> = Preview,
            <Indicator color="red" /> = Inactive
          </Row>
          {blockLists.map((block, index) => {
            var _active = block.dateList.find(
              (f) => getBlockMode(f) === BlockMode.PRESENT
            );

            let isPreview = !!block.previewSchemaGID;
            let indicator = <Indicator color="orange" />;

            if (block.isLive) {
              indicator = <Indicator color="green" />;
            } else if (!block.previewSchemaGID) {
              indicator = <Indicator color="red" />;
            }

            return (
              <div key={`${block.title}-${index}`}>
                <TitleRow>
                  <Label
                    labelStyle={{ marginBottom: '0px', maxWidth: '220px' }}
                    label={
                      <span
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleBlockClick(block, !!_active ? _active.id : '');
                        }}
                      >
                        {indicator}
                        <span
                          style={{ display: 'inline-block', maxWidth: '180px' }}
                        >
                          {block.title}
                        </span>
                        {activeZones.includes(block.title) ? (
                          <IoCaretDown />
                        ) : (
                          <IoCaretForward />
                        )}
                      </span>
                    }
                  >
                    {' '}
                    {canEdit && !!soloZone && !!isPreview && !block.isLive && (
                      <Button
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                          var guid = block.schemaGID;
                          isFetching(true);
                          new PriceV3Api()
                            .post(
                              `zone/${block.zone.id}/setselectedpricing/${guid}`,
                              '{}'
                            )
                            .then((sch: PriceSchedule) => {
                             
                              isFetching(false);
                              dispatch(
                                zoneUpdateSchedule(block.zone.id, guid, sch)
                              );
                            });
                        }}
                      >
                        Set as live
                      </Button>
                    )}
                    {canEdit && !!soloZone && !isPreview && (
                      <Button
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                          var guid = block.schemaGID;
                          isFetching(true);
                          new PriceV3Api()
                            .post(
                              `zone/${block.zone.id}/setpreviewschedule/${guid}`,
                              '{}'
                            )
                            .then((sch: PriceSchedule) => {
                             
                              isFetching(false);
                              dispatch(
                                zoneUpdateSchedule(block.zone.id, guid, sch)
                              );
                            });
                        }}
                      >
                        Set as preview
                      </Button>
                    )}
                    {canEdit &&
                      !soloZone &&
                      block.alternativeSchemas.length > 0 && (
                        <Select
                          style={{
                            marginTop: '10px',
                            maxWidth: '100%',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                          onChange={(e) => {
                            // console.log(
                            //   'Setting active:' + (e.target as HTMLInputElement).value
                            // );
                            var selectedGid = e.target.value;
                            onSelectChange(block.zone.gid, selectedGid);

                            // isFetching(true);
                            // handleCloseSeasonCardRow(block);
                            // new PriceV3Api()
                            //   .post(
                            //     `zone/${block.zone.id}/setpreviewschedule/${selectedGid}`,
                            //     '{}'
                            //   )
                            //   .then((sch: PriceSchedule) => {
                            //     console.log(
                            //       'Sent req for:' +
                            //         selectedGid +
                            //         ' and got schema:',
                            //       sch
                            //     );
                            //     isFetching(false);
                            //     dispatch(
                            //       zoneUpdateSchedule(
                            //         block.zone.id,
                            //         selectedGid,
                            //         sch
                            //       )
                            //     );
                            //   });
                          }}
                        >
                          {block.alternativeSchemas.map((as) => (
                            <option
                              key={as.gid}
                              value={as.gid}
                              selected={
                                as.gid === block.schemaGID ? true : undefined
                              }
                            >
                              {as.name}
                            </option>
                          ))}
                        </Select>
                      )}
                  </Label>
                </TitleRow>
                <SeasonRow
                  style={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                  }}
                  expanded={activeZones.includes(block.title)}
                >
                  {canEdit && (
                    <Button
                      color={ButtonColors.add}
                      onClick={() => {

                        var sch = block.zone.schedules.find(
                          (sch) => sch.gid === block.schemaGID
                        )!;

                        modalStack.push(
                          <>
                            <AddPriceProductModal
                              zone={block.zone}
                              parentSchedule={sch}
                              onAddPricing={() => {}}
                              onAddSchedule={(newSch) => {
                              
                                dispatch(
                                  zoneUpdateSchedule(
                                    block.zone.id,
                                    sch.gid,
                                    newSch
                                  )
                                );
                              }}
                            />
                          </>
                        );
                      }}
                    >
                      Add pricing block
                    </Button>
                  )}
                  {canEdit && !isPreview && !block.isLive && (
                    <Button
                      style={{ marginTop: '25px' }}
                      onClick={() => {
                        modalStack.push(
                          <ConfirmRemove
                            objectTitle="price schedule"
                            objectName={block.title}
                            onClose={() => {
                              modalStack.pop();
                            }}
                            onSuccess={() => {
                              modalStack.pop();
                              isFetching(true);
                              new PriceV3Api()
                                .delete(
                                  `season/schedule/delete/${block.schemaGID}`
                                )
                                .then((result) => {
                                  isFetching(false);
                                  dispatch(updateSchedule(result));
                                });
                            }}
                          />
                        );
                      }}
                      color={ButtonColors.remove}
                    >
                      Remove schedule
                    </Button>
                  )}
                </SeasonRow>
              </div>
            );
          })}
        </TitleBlock>
        <ContentWrapper
          id="content-wrapper"
          ref={contentWrapperRef}
          onScroll={handleScroll}
        >
          {renderBlocks()}
        </ContentWrapper>
      </Content>
      {fetching && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#ffffff',
            zIndex: 100,
            opacity: 0.35,
          }}
        >
          <Loader title={'Could not find data'} forceError={false} />
        </div>
      )}
    </DateBlockWrapper>
  );
};

export default DateBlock;
