import React from 'react';
import { useConfigContext } from '../contexts/configurationContext';
import { ReactTable } from '@bitio/react-components';
import Button, { RoundButton } from 'components/input/Button';
import useModalStack from 'components/Modal/useModalStack';
import GroupModal from './GroupModal';
import { FaTrashAlt } from 'react-icons/fa';
import RemoveModal from './RemoveModal';
import { styled } from 'styled-components';

const CellWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
const ConfigGroups: React.FC = () => {
  const { groups } = useConfigContext();
  const modal = useModalStack();

  //TODO: Check react table  > sök på tan stack table & Storybook
  return (
    <ReactTable
      className=""
      data={groups}
      columns={[
        { header: 'Group Name', accessorKey: 'name' },
        { header: 'Zones', accessorFn: (row) => row.zoneIds.length },
        {
          header: 'Edit',
          accessorKey: 'id',
          cell: (props) => (
            <CellWrapper>
              <Button
                onClick={() => {
                  modal.push(<GroupModal editableGroup={props.row.original} />);
                }}
              >
                Edit
              </Button>
              <RoundButton
                onClick={() => {
                  modal.push(<RemoveModal group={props.row.original} />);
                }}
                title="Removed group"
              >
                <FaTrashAlt size={20} />
              </RoundButton>
            </CellWrapper>
          ),
        },
      ]}
    />
  );
};

export default ConfigGroups;

