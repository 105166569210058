import React from 'react';
import Main from 'components/Main';
import NavBar from 'components/NavBar';
import ConfigBar from './components/ConfigBar';
import { Switch, Route } from 'react-router';
import Routes from 'constants/Route';
import styled from 'styled-components';
import ConfigGroups from './components/ConfigGroups';
import ConfigZones from './components/ConfigZones';

const Content = styled.div`
  display: flex;
  padding: 20px 0;
  flex-direction: column;
`;
const ChildContent = styled.div``;

const Configuration: React.FC = () => {
  return (
    <Main>
      <NavBar />
      <Content>
        <ConfigBar />
        <ChildContent>
          <Switch>
            <Route path={Routes.ConfigurationGroups} component={ConfigGroups} />
            <Route path={Routes.ConfigurationZones} component={ConfigZones} />
          </Switch>
        </ChildContent>
      </Content>
    </Main>
  );
};

export default Configuration;

