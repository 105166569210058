import React from 'react';
import { Checkbox } from '@bitio/react-components';
import { Card } from 'components/Card';
import { BsListCheck } from 'react-icons/bs';
import { styled } from 'styled-components';

const Title = styled.h4`
  font-size: 16px;
  margin: 0;
`;
export const CheckboxList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px;
`;

interface ScrollableCheckboxProps {
  zones: { gid: string; name: string }[];
  selectedZoneIds: string[];
  onChange: (selectedZoneIds: string[]) => void;
}
const ScrollableCheckbox: React.FC<ScrollableCheckboxProps> = ({
  zones,
  selectedZoneIds,
  onChange,
}) => {
  const handleCheckboxChange = (id: string) => {
    if (selectedZoneIds.includes(id)) {
      onChange(selectedZoneIds.filter((zoneId) => zoneId !== id));
    } else {
      onChange([...selectedZoneIds, id]);
    }
  };

  return (
    <Card.Wrapper scrollable>
      <Card.Header scrollable>
        <BsListCheck size={20} />
        <Title>Zones</Title>
      </Card.Header>
      <Card.Content scrollable>
        <CheckboxList>
          {zones.map((zone) => {
            return (
              <Checkbox
                key={zone.gid}
                type="checkbox"
                id={zone.gid}
                value={zone.name}
                checked={selectedZoneIds.includes(zone.gid)}
                onChange={() => handleCheckboxChange(zone.gid)}
              >
                {zone.name}
              </Checkbox>
            );
          })}
        </CheckboxList>
      </Card.Content>
    </Card.Wrapper>
  );
};

export default ScrollableCheckbox;

