import {
  selectHistoryFacilityId,
  selectHistoryFacilityMatch,
} from 'store/router/selector';
import { ApplicationState } from '../definitions';
import { UISettings } from './reducer';

export function getFirstUserFacility(state: ApplicationState) {
  return state.uiSettings.user.facilities[0];
}

export function getUser(state: ApplicationState) {
  return state.uiSettings.user;
}

export function getDevEnvironment(state: ApplicationState) {
  return state.uiSettings.env === 'Development';
}

export function getAdminStatus(state: ApplicationState) {
  var facilityId = selectHistoryFacilityId(state);
  if (
    !!facilityId &&
    (state.uiSettings.siteAdmin.includes(facilityId) ||
      state.uiSettings.orgAdmin.includes(facilityId))
  ) {
    return true;
  }
  return state.uiSettings.hasUserAdmin || state.uiSettings.isDev;
}

export function hasEditRights(state: ApplicationState): boolean {
  var facilityId = selectHistoryFacilityId(state);
  if (
    !!facilityId &&
    (state.uiSettings.siteAdmin.includes(facilityId) ||
      state.uiSettings.siteWrite.includes(facilityId))
  ) {
    return true;
  }
  return state.uiSettings.isDev;
}

export function selectUISettingSites(state: ApplicationState) {
  return state?.uiSettings?.sites;
}
export function selectUISettingZones(state: ApplicationState) {
  return state?.uiSettings?.siteZones;
}

export function selectUISettings(state: ApplicationState) {
  return state?.uiSettings;
}
